<template>
  <div>
    <v-navigation-drawer
      v-model="isEventHandlerDrawerActive"
      :right="!$vuetify.rtl"
      touchless
      app
      temporary
      width="420"
    >
      <SideBarCalendarLeft
        :mode="mode"
        :event="event"
        @close-drawer="isEventHandlerDrawerActive = false"
        @updated-calendar="updatedCalendar"
      />
    </v-navigation-drawer>

    <div
      v-if="refCalendar"
      class="calendar-header mx-6 my-5 d-flex align-center flex-wrap"
    >
      <v-btn
        icon
        class="me-1"
        @click="refCalendar.prev()"
      >
        <v-icon size="30">
          {{ icons.mdiChevronLeft }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        class="me-3"
        @click="refCalendar.next()"
      >
        <v-icon size="30">
          {{ icons.mdiChevronRight }}
        </v-icon>
      </v-btn>
      <p class="font-weight-semibold text-xl text--primary mb-0 me-6">
        {{ refCalendar.title }}
      </p>

      <v-spacer></v-spacer>

      <v-btn-toggle
        v-model="activeCalendarView"
        color="primary"
        class="mt-3 mt-sm-0"
      >
        <v-btn
          v-for="calendarViewOption in calendarViewOptions"
          :key="calendarViewOption"
          elevation="0"
          :value="calendarViewOption"
        >
          {{ calendarViewOption }}
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-card class="max-h-content-container">
      <v-calendar
        ref="refCalendar"
        v-model="calendarValue"
        :event-color="getEventColor"
        :updated-calendar="updatedCalendar"
        :type="activeCalendarView"
        :events="calendarEvents"
        :event-margin-bottom="5"
        @click:event="calenderHandleEventClick"
        @click:day="calendarHandleDayClick"
        @click:time="calendarHandleDayClick"
        @click:more="calendarHandleMoreClick"
      >
      </v-calendar>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { getVuetify } from '@core/utils'
import { mdiChevronLeft, mdiChevronRight, mdiMenu } from '@mdi/js'
import { ref } from '@vue/composition-api'
import SideBarCalendarLeft from './SideBarCalendarLeft.vue'

export default {
  components: {
    SideBarCalendarLeft,
  },
  data() {
    return {
      colorEvent: '',
      mode: '',
      openModal: false,
      isEventHandlerDrawerActive: false,
      eventClickBugWorkaround: false,
      calendarEvents: [],
      calendarValue: '',
      activeCalendarView: 'month',
      calendarViewOptions: ['month', 'week', 'day'],
      icons: {
        mdiMenu,
        mdiChevronLeft,
        mdiChevronRight,
      },
    }
  },

  watch: {
  },

  created() {
    this.getItems()
  },

  methods: {
    async getItems() {
      await axiosIns.get('/api/v1/integration/schedule/index')
        .then(res => {
          this.calendarEvents = res.data.data
        })
    },

    getEventColor(event) {
      if (event.status === 'Concluído') {
        return 'success'
      } if (event.status === 'Cancelado') {
        return 'error'
      }

      return 'info'
    },

    calenderHandleEventClick(eventObj) {
      this.mode = 'edit'
      this.isEventHandlerDrawerActive = true
      this.event = eventObj.event

      this.eventClickBugWorkaround = true
    },

    calendarHandleDayClick(dayObj) {
      if (this.eventClickBugWorkaround) {
        this.eventClickBugWorkaround = false

        return
      }
      this.mode = 'add'

      this.isEventHandlerDrawerActive = true

      this.event = dayObj.date
    },

    calendarHandleMoreClick({ date }) {
      this.calendarValue = date

      this.activeCalendarView = 'day'

      this.eventClickBugWorkaround = true
    },

    async updatedCalendar() {
      await this.getItems()
    },

  },

  setup() {
    const refCalendar = ref(null)

    const blankEvent = {
      id: '',
      name: '',
      cpf_cnpj: '',
      birthDate: '',
      address: '',
      secondName: '',
      vehicleModel: '',
      plan: '',
      cellphone: '',
      start: '',
      end: '',
      timed: false,
    }
    const event = ref(JSON.parse(JSON.stringify(blankEvent)))

    const $vuetify = getVuetify()

    return {
      refCalendar,
      event,
    }
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/apps/calendar.scss';
</style>
