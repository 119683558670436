<template>
  <div
    :key="event.id"
    class="h-full"
  >
    <div class="drawer-header d-flex align-center mb-4">
      <span class="font-weight-semibold text-base text--primary">{{ event.id ? 'Atualizar' : 'Inserir' }}</span>
      <v-spacer></v-spacer>

      <v-btn
        v-if="event.id"
        icon
        small
        class="me-1"
        @click="deleteScheduling"
      >
        <v-icon size="22">
          {{ icons.mdiTrashCanOutline }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        small
        @click="$emit('close-drawer')"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <perfect-scrollbar
      v-if="mode === 'add'"
      class="ps-calendar-event-handler"
    >
      <v-form
        ref="refCalendarEventHandlerForm"
        class="px-5 pt-3 pb-10"
        @submit.prevent="handleFormSubmit"
      >
        <v-text-field
          v-model="eventData.name"
          label="Nome cliente"
          placeholder="Nome"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-text-field
          v-model="eventData.cpf"
          label="CPF cliente"
          placeholder="CPF"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-menu
          v-model="isStartDateMenuOpen"
          :close-on-content-click="false"
          :config="{ enableTime: true, noCalendar: true, dateFormat: 'Y-m-d H:i' }"
          transition="scale-transition"
          offset-y
          eager
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="eventData.birth_date"
              label="Data de nascimento"
              readonly
              outlined
              dense
              class="mb-6"
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="eventData.birth_date"
            @input="isStartDateMenuOpen = false"
          ></v-date-picker>
        </v-menu>

        <v-text-field
          v-model="eventData.address"
          label="Endereço"
          placeholder="Endereço"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-text-field
          v-model="eventData.nickname"
          label="Apelido"
          placeholder="Apelido"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-autocomplete
          v-model="eventData.vehicle_model"
          :items="listVehiclesItems[0]"
          item-text="vehicle_model"
          item-value="vehicle_model"
          label="Modelo veículo"
          placeholder="Modelo"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-autocomplete
          v-model="eventData.sales_plan"
          :items="salesPlanList"
          label="Plano"
          placeholder="Plano"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-text-field
          v-model="eventData.phone_cell"
          label="Telefone"
          placeholder="Telefone"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-menu
          v-model="isStartDateMenuOpenMeeting"
          :close-on-content-click="false"
          :config="{ enableTime: true, noCalendar: true, dateFormat: 'Y-m-d H:i' }"
          transition="scale-transition"
          offset-y
          disabled
          eager
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="event"
              label="Data agendamento"
              disabled
              outlined
              dense
              class="mb-6"
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="event"
            readonly
            @input="isStartDateMenuOpenMeeting = false"
          ></v-date-picker>
        </v-menu>

        <v-textarea
          v-model="eventData.comments"
          outlined
          label="Comentários"
          placeholder="Comentários"
        ></v-textarea>

        <v-btn
          color="primary"
          class="me-3"
          type="submit"
        >
          Inserir
        </v-btn>
        <v-btn
          outlined
          @click="clearForm"
        >
          Limpar
        </v-btn>
      </v-form>
    </perfect-scrollbar>

    <perfect-scrollbar
      v-else
      class="ps-calendar-event-handler"
    >
      <v-form
        ref="refCalendarEventHandlerForm"
        class="px-5 pt-3 pb-10"
        @submit.prevent="updateForm"
      >
        <v-text-field
          v-model="event.name"
          label="Nome cliente"
          placeholder="Nome"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-text-field
          v-model="event.cpf"
          label="CPF cliente"
          placeholder="CPF"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-menu
          v-model="isStartDateMenuOpen"
          :close-on-content-click="false"
          :config="{ enableTime: true, noCalendar: true, dateFormat: 'Y-m-d H:i' }"
          transition="scale-transition"
          offset-y
          eager
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="event.birth_date"
              label="Data de nascimento"
              readonly
              outlined
              dense
              class="mb-6"
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="event.birth_date"
            @input="isStartDateMenuOpen = false"
          ></v-date-picker>
        </v-menu>

        <v-text-field
          v-model="event.address"
          label="Endereço"
          placeholder="Endereço"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-text-field
          v-model="event.nickname"
          label="Apelido"
          placeholder="Apelido"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-autocomplete
          v-model="event.vehicle_model"
          :items="listVehiclesItems[0]"
          item-text="vehicle_model"
          item-value="vehicle_model"
          label="Modelo veículo"
          placeholder="Modelo"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-autocomplete
          v-model="event.sales_plan"
          :items="salesPlanList"
          label="Plano"
          placeholder="Plano"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-text-field
          v-model="event.phone_cell"
          label="Telefone"
          placeholder="Telefone"
          hide-details="auto"
          outlined
          dense
          class="mb-6"
        />

        <v-menu
          v-model="isStartDateMenuOpenMeeting"
          :close-on-content-click="false"
          :config="{ enableTime: true, noCalendar: true, dateFormat: 'Y-m-d H:i' }"
          transition="scale-transition"
          offset-y
          eager
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="event.date_meeting"
              label="Data agendamento"
              readonly
              outlined
              dense
              class="mb-6"
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="event.date_meeting"
            @input="isStartDateMenuOpenMeeting = false"
          ></v-date-picker>
        </v-menu>

        <v-autocomplete
          v-model="event.status"
          :items="itemSelect"
          :item-text="item => item.label"
          :item-value="item => item.label"
          dense
          label="Status"
          placeholder="Status"
          outlined
          hide-details
          class="mb-6"
        >
          <template #selection="{ item }">
            <div class="d-flex align-center">
              <v-badge
                inline
                :color="item.color"
                dot
                class="me-2"
              ></v-badge>
              <span>{{ item.label }}</span>
            </div>
          </template>
        </v-autocomplete>

        <v-textarea
          v-model="event.comments"
          outlined
          label="Comentários"
          placeholder="Comentários"
        ></v-textarea>

        <v-btn
          color="primary"
          class="me-3"
          type="submit"
          @click="updateForm"
        >
          Atualizar
        </v-btn>
        <v-btn
          outlined
          @click="$emit('close-drawer')"
        >
          Cancelar
        </v-btn>
      </v-form>
    </perfect-scrollbar>
  </div>
</template>
<script>import axiosIns from '@/plugins/axios'
import { mdiClose, mdiTrashCanOutline } from '@mdi/js'

// import { required, urlValidator } from '@core/utils/validation'

// import { ref } from '@vue/composition-api'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    updatedCalendar: {
      type: Function,
      default: () => {},
    },
    mode: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },

    // clearEventData: {
    //   type: Function,
    //   required: true,
    // },
  },
  data() {
    return {
      listVehiclesItems: [],
      salesPlanList: [
        'Especial',
        'Super Legal',
        'Master',
        'Conquista',
        'TRX E CRF',
        'Mega Fácil',
        'Minha Scooter Honda',
        'Multichances',
        'Vou de Honda',
        'Vou de Honda +',
      ],
      itemSelect: [
        { label: 'Cancelado', color: 'error' },
        { label: 'Concluído', color: 'success' },
        { label: 'Agendado', color: 'info' },
      ],
      eventData: {
        name: '',
        cpf: '',
        address: '',
        birth_date: '',
        nickname: '',
        vehicle_model: '',
        sales_plan: '',
        phone_cell: '',
        date_meeting: '',
        comments: '',
        end: '',
        start: '',
        status: 'Agendado',
      },
      isStartDateMenuOpen: false,
      isStartDateMenuOpenMeeting: false,
      icons: {
        mdiTrashCanOutline,
        mdiClose,
      },
    }
  },

  created() {
    this.getItemsVehicles()
  },

  methods: {
    async getItemsVehicles() {
      await axiosIns.get('api/v1/integration/vehicle_model/index')
        .then(res => {
          this.listVehiclesItems.push(res.data.data)
        })
    },
    async handleFormSubmit() {
      this.eventData.start = this.eventData.date_meeting
      this.eventData.end = this.eventData.date_meeting
      this.eventData.date_meeting = this.event

      axiosIns.post('/api/v1/integration/schedule/store', this.eventData)
        .then(async () => {
          await this.$swal({
            icon: 'success',
            title: 'Concluído!',
            text: 'Seu agendamento foi realizado!',
            showConfirmButton: false,
            timer: 3000,
          })
          this.$emit('close-drawer')
          this.$emit('updated-calendar')
        })
        .catch(async error => {
          await this.$swal({
            icon: 'error',
            title: 'Erro ao realizar agendamento!',
            text: error,
          })
          this.$emit('close-drawer')
        })
      this.$emit('updated-calendar')
    },

    async updateForm() {
      const body = {
        name: this.event.name,
        cpf: this.event.cpf,
        address: this.event.address,
        birth_date: this.event.birth_date,
        nickname: this.event.nickname,
        vehicle_model: this.event.vehicle_model,
        sales_plan: this.event.sales_plan,
        phone_cell: this.event.phone_cell,
        date_meeting: this.event.date_meeting,
        comments: this.event.comments,
        status: this.event.status,
      }
      axiosIns.put(`/api/v1/integration/schedule/update/${this.event.id}`, body)
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Dados do agendamento atualizados!',
            showConfirmButton: false,
            timer: 2000,
          })
          this.$emit('close-drawer')
          this.$emit('updated-calendar')
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao atualizar agendamento!',
            showConfirmButton: false,
            text: error,
          })
          this.$emit('close-drawer')
        })
      this.$emit('updated-calendar')
    },

    clearForm() {
      this.eventData = {
        name: '',
        cpf: '',
        address: '',
        birth_date: '',
        nickname: '',
        vehicle_model: '',
        sales_plan: '',
        phone_cell: '',
        date_meeting: '',
        comments: '',
        end: '',
        start: '',
        status: '',
      }
    },

    async deleteScheduling() {
      axiosIns.delete(`/api/v1/integration/schedule/destroy/${this.event.id}`)
        .then(async () => {
          await this.$swal({
            icon: 'success',
            title: 'Excluido!',
            text: 'O agendamento foi excluído!',
            showConfirmButton: false,
            timer: 2000,
          })
          this.$emit('updated-calendar')
          this.$emit('close-drawer')
        })
        .catch(async error => {
          this.$emit('close-drawer')
          await this.$swal({
            icon: 'error',
            title: 'Erro ao excluir agendamento!',
            text: error,
          })
        })
      this.$emit('updated-calendar')
    },
  },

}
</script>
<style lang="scss">
.ps-calendar-event-handler {
  height: calc(100% - 44px - 24px - 2px);

  // Fixes: if chips row >2 => Create enough space between chips row & avoid first row chip touching the label
  // Related Issue: https://github.com/vuetifyjs/vuetify/issues/13107
  .select-guest {
    .v-select__selections {
      .v-chip--select {
        margin-bottom: 6px;
        margin-top: 6px;
      }
    }
  }
}
</style>
